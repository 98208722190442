import React from 'react';
import { PageProps } from 'gatsby';
import { FormattedMessage, useIntl } from 'react-intl';
import { Layout } from 'components/layout';
import { withIntlProvider } from 'components/withIntl'; 
import { Language } from 'types';

interface PageContext {
  locale?: Language;
}

type Props = PageProps<GatsbyTypes.SiteMetadataQuery, PageContext>;

const NotFoundPage = (props: Props) => {
  const intl = useIntl();

  const title = intl.formatMessage({
    id: '404_title',
    defaultMessage: '404: Puslapis nerastas'
  });

  return (
    <Layout
      location={props.location}
      seoTitle={title}
      title={title}
      showSlider
    >
      <FormattedMessage
        id="404_description"
        defaultMessage="Deja šis puslapis neegzistuoja."
      />
    </Layout>
  );
};

export default withIntlProvider(NotFoundPage);
